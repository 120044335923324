<template>
  <div>
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Ready-to-go Challenges</h1>
      <p>Templates are ready-to-go challenges, including virtual course maps, badges, custom emails and are a great way to quickly launch engaging challenges.</p>
      <p>
        Select one of the templates below to see details of each of the ready-to-go challenges.
        Or <router-link :to="{name: 'challengeJourneys'}">explore ready-to-go journeys</router-link> and our <router-link :to="{name: 'challengeWellbeingPacks'}">wellbeing content packs</router-link> you can add to your challenges.
      </p>
      <v-alert type="info" outlined><strong>Beta</strong>: Challenge Templates are new. Questions or suggestions? <router-link to="/contact">Contact Us</router-link>.</v-alert>
    </div>
    
    <EventTemplateGrid routeName="challengeTemplateView" :filter="filter" />

    <!-- <div v-if="!betaMode" class=" pb-6 pb-md-12">
      <div class="container">
        <h2>Explore ready-to-go journeys</h2>
        <v-row >
          <v-col v-for="(item, idx) in templates" :key="idx" cols="4">
            <MapTemplateCard :item="item"  :to="{name: 'challengeJourneyView', params: {id : item.id }}" />
          </v-col>
        </v-row>
      </div>
    </div>  -->

    <div>
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h3>Create your custom challenges</h3>
            <p>Next to these ready-to-go challenges, you can easily create your own challenge based on your own custom map and theme. Reach out to us to get started.</p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="green white--text py-6 py-md-12 my-4">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Go Live Today!</h2>
            <h1 class="subtitle">Create your personalized fitness challenge within minutes!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
              <v-btn v-if="false" class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import assetsService from "@/services/assetsService";
import navData from '@/data/nav.json'
import EventTemplateGrid from '@/components/templates/EventTemplateGrid.vue'
import MapTemplateCard from '@/components/templates/MapTemplateCard.vue'

export default {
  name: "Contact",
  components: {
    EventTemplateGrid,
    MapTemplateCard,
  },
  props: {
  },
  data: function() {
    return {
      navData: navData,
      templates: null,
      filter: null,
    };
  },
  created() {
  },
  async mounted() {
    this.templates = (await assetsService.getMapTemplates()).data.data;

    if (this.$route.query.tags) {
      //console.log('/// set filter, tags', this.$route.query.tags);
      this.filter = { tags: this.$route.query.tags.split(',') };
    }
  },
  methods: {
   
  },
  watch: {
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
  },

};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>